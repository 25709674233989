.styled_toast {
  padding: 0px !important;
  margin: 0px !important;
  width: max-content !important;
  min-height: 60px !important;
  border-radius: 4px !important;
}

.toast_styled_container {
  top: 80px !important;
  right: 140px !important;
  padding: 0 !important;
  margin: 0 !important;
  width: max-content !important;
}

.toast_styled_body {
  background-color: var(--color-black);
  color: var(--color-white);
  font-family: var(--default-font-family);
  margin: 0 !important;
  display: grid;
  align-items: center;
}
