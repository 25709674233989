.react-datepicker {
  border: none;
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  padding: 0 20px 10px;
}

.react-datepicker-popper {
  margin-top: 2px !important;
  margin-bottom: 5px !important;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__week {
  height: 26px;
}

.react-datepicker__header {
  background: white;
  mix-blend-mode: normal;
  border-bottom: 1px solid #ededed;
  color: #a6a6a6;
}

.react-datepicker__current-month {
  color: #111111;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 35px;
  height: 35px;
  text-align: center;
  letter-spacing: -0.25px;
  text-transform: uppercase;
}
.react-datepicker__navigation--next {
  width: 25px;
  height: 25px;
  right: 67px;
  top: 15px;
}

.react-datepicker__navigation--previous {
  width: 25px;
  height: 25px;
  left: 67px;
  top: 15px;
}

.react-datepicker__navigation--next--disabled {
  width: 25px;
  height: 25px;
  right: 67px;
  top: 15px;
}

.react-datepicker__day {
  width: 2.5rem;
  line-height: 2.5rem;
}
.react-datepicker__day--selected {
  background: #ff9152;
  border-radius: 100%;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background: #ff9152;
  border-radius: 100%;
  opacity: 0.37;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  background: #eeeeee;
  border-radius: 100%;
}

.react-datepicker__day-name {
  margin: 0.9rem;
  font-size: 1.3rem;
}

.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.5rem;
  font-size: 1.2rem;
}

.react-datepicker__day-name {
  color: #a6a6a6;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #a6a6a6;

  mix-blend-mode: normal;
}

.react-datepicker__triangle {
  display: none;
  visibility: hidden;
}

.react-datepicker__input-container input {
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 4px;
  width: inherit;
  height: 52px;
  padding-left: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  background: url('/src/assets/icons/calendar-gray.svg') no-repeat;

  background-position-x: calc(100% - 12px);
  background-position-y: center;
}

.react-datepicker__input-container input:hover {
  outline: none;
  border: 1px solid #ff9152;
}

.react-datepicker__input-container input:focus {
  outline: none;
  border: 1px solid #ff9152;
}
.react-datepicker__navigation--years-previous {
  border-top-color: black;
}

.react-datepicker__navigation--years-upcoming {
  border-bottom-color: black;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: white;
  position: absolute;
  width: 50%;
  height: 200px;
  left: 25%;
  top: 40px;
  text-align: center;
  border-radius: 0.3rem;
  border: none;
  font-size: 1.4rem;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.26);
  font-weight: 600;
  font-family: Open Sans;
  font-style: normal;
  line-height: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: black;
  mix-blend-mode: normal;
}

.react-datepicker__header__dropdown,
.react-datepicker__header__dropdown--scroll {
  margin: 10px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: black;
  mix-blend-mode: normal;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background: #eeeeee;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: 34px;
}

.react-datepicker__day--outside-month {
  color: #ccc;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 27px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: black;
  mix-blend-mode: normal;
}
